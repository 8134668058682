.main{
  background-color: white;
  max-height: 100vh;


}


.form {
    padding: 0rem;
    text-align: center;

  }
  
  .control {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap-reverse;
    margin-left: 9%;
  }
  
  .control label {
    display: flex;
    margin-top: 3%;
    font-size: 14px;
    font-weight: normal;
    font-family:'Roboto', sans-serif;;
    margin-left: 280px;
    padding-right: 15px;
    color: #1D1D1B;
}
  
  .control input,
  .control textarea {
    margin-top: 10px;
    display: block;
    font: Roboto-Medium;
    border-radius: 2px;
    border: 1px solid #1D1D1B;
    padding: 8px 1px 8px 1%;
    width: 15%;
    height: 55%;
  }
  
  .actions {
    margin-top: 1.5rem;
    text-align: center;
    display: block;
    margin-right: 55px;
  
    
  }
  .actions label{
    font-size: 12px;
    margin-right: 60px;
    color: #0844a4 ;
    font-family: 'Roboto', sans-serif;
    
  }

  
  .actions button {
    width: 115px;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 75%;
    margin: 3px 7px;
    background-color: #597dda;
    border: 1px solid #1D1D1B;
    border-radius: 3px;
    color: #ffffff;
    font-weight: small;
    cursor: pointer
  }
  
  .actions button:hover,
  .actions button:active {
    background-color:#597dda;
    border-color: 1px solid #1D1D1B;

  }

  .radioControl {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap-reverse;
    margin-left: 40%;
    font-family: "Roboto",sans-serif;
    font-size: 12px;
    
  }
  .email_styles{
    font-family: "Roboto",sans-serif;
}