.main {
    margin: 1rem auto;
    width: 90%;
    max-width: 60rem;
    max-height: 100vh;
    font-family: Roboto-Regular !important;
  }
  .message {
    background-color: white;
    color: rgb(34, 184, 34);
    text-align: center;
    margin-top: 40px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
  }
  .errormessage {
    background-color: white;
    color: red;
    text-align: center;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
  }
  .errormessages {
    background-color: white;
    color: red;
    text-align: center;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
  }
  .headerimage{
    background: linear-gradient(to bottom,  #ffffff 0%,#cce3f6 100%);
  }
  .login_content{
    width: 80%;
    margin-left: 20%;
    text-align: center;
    color: #1D1D1B;
  }
 
  